import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import AuthService from '../services/AuthService';
import AppService from '../services/AppService';
import { IUpdateUserDto, IUserDto } from '../types/user/userDto';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: IUserDto | null;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: IUserDto | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: IUserDto;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type UpdateAction = {
  type: 'UPDATE';
  payload: {
    data: IUpdateUserDto;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | UpdateAction;

interface AuthContextValue extends State {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  update: (data: IUpdateUserDto) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: any): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: any): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  UPDATE: (state: State, action: any): State => {
    const { data } = action.payload;
    return {
      ...state,
      user: {
        ...state.user!,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        nationality: data.nationality,
        id: state?.user?.id as number,
        roles: state?.user?.roles || []
      }
    };
  }
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  update: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const accessToken = AppService.getToken();

      if (accessToken) {
        const user = await AuthService.me();

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const res = await AuthService.login({
      email,
      password,
    });

    const { user, access_token: accessToken } = res;
    AppService.setToken(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = async (): Promise<void> => {
    AppService.clearToken();
    dispatch({ type: 'LOGOUT' });
  };

  const update = async (data: IUpdateUserDto): Promise<void> => {
    dispatch({
      type: 'UPDATE',
      payload: {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          nationality: data.nationality,
        }
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        update
      }}
    >
     {children} 
    </AuthContext.Provider>
  );
};

export default AuthContext;
