import { Paper } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import styles from '../styles/tutorial.module.css';
import InformationPopover from '../components/custom/InformationPopover';

const Tutorials = () => {

  const appText = [
    t('tutorialAppText'),
    t('tutorialAppText1'),
  ]

  const excelText = [
    t('tutorialApp1'),
    t('tutorialApp2'),
    t('tutorialApp3'),
    t('tutorialApp4'),
    t('tutorialApp5'),
    t('tutorialApp6'),
    t('tutorialApp7'),
    t('tutorialApp8'),
  ]

	return (
		<div className={styles.background}>
			<div className={styles.videoWrapper}>
				<div className={styles.videoText}>
					<h1>{t('tutorialApp')}</h1>
          <div className={styles.info}>
					  <InformationPopover text={appText} />
          </div>
				</div>
				<Paper elevation={12}>
					<video width='99%' height='50%' controls>
						<source src={`${process.env.PUBLIC_URL}/tutorial.mp4`} type='video/mp4' />
					</video>
				</Paper>
			</div>
			<br />
			<br />
			<div className={styles.videoWrapper}>
				<div className={styles.videoText}>
					<h1>{t('tutorialExcel')}</h1>
          <div className={styles.info}>
					  <InformationPopover text={excelText} />
          </div>
				</div>
				<Paper elevation={12}>
					<video width='99%' height='50%' controls>
						<source src={`${process.env.PUBLIC_URL}/excel.mp4`} type='video/mp4' />
					</video>
				</Paper>
			</div>
		</div>
	);
};

export default Tutorials;
