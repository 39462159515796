import axios, { AxiosRequestConfig } from 'axios';
import AppService from '../services/AppService';
import getEnv from '../utils/Env';

const axiosInstance = axios.create({
	//baseURL: "http://localhost:3000"
	baseURL: "https://aca-dev-backend.politecliff-57a4ebcf.swedencentral.azurecontainerapps.io"
});

axiosInstance.interceptors.request.use(async config => {
	const accessToken = AppService.getToken();
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}
	return config;
});

axiosInstance.interceptors.response.use(
	async (res: any) => res?.data,
	(err: any) => {
		if (err.response?.status === 401) {
			AppService.clearToken();
		}
		return Promise.reject(err);

	}
);

export default axiosInstance;
