import api from "../lib/axios";
import { ILoginDto } from "../types/authentication/loginDto";
import { ILoggedInDto } from "../types/user/loggedInDto";
import { IUserDto } from "../types/user/userDto";


class AuthService {
  static login = ({ email, password }: ILoginDto): Promise<ILoggedInDto> => api.post('/auth/login', { email, password });

  static me = (): Promise<IUserDto> => api.get('/auth/me');
}

export default AuthService;