import React from 'react';
import LoginPage from './pages/authentication/LoginPage';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import './styles/global.css';

function App() {

  const content = useRoutes(routes);
  
  return (
    <div>
      {content}
    </div>
  );
}

export default App;
