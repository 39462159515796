import { Suspense, lazy } from 'react';
import { ROLES } from './constants';
import { Navigate, Outlet } from 'react-router-dom';
import Tutorials from './pages/Tutorials';

const Loadable = (Component: any) => (props: any) =>
	(
		<Suspense>
			<Component {...props} />
		</Suspense>
	);

const LogInOverlay = Loadable(lazy(() => import('./components/overlays/LogInOverlay')));
const MainOverlay = Loadable(lazy(() => import('./components/overlays/MainOverlay')));
const LoginPage = Loadable(lazy(() => import('./pages/authentication/LoginPage')));
const OverviewSuperAdmin = Loadable(lazy(() => import('./pages/superAdmin/Overview')));
const OverviewAdmin = Loadable(lazy(() => import('./pages/admin/Overview')));
const OverviewWorker = Loadable(lazy(() => import('./pages/worker/Overview')));
const Generate = Loadable(lazy(() => import('./pages/worker/Generate')));
const GuestGuard = Loadable(lazy(() => import('./components/guards/GuestGuard')));
const AuthGuard = Loadable(lazy(() => import('./components/guards/AuthGuard')));
const RoleGuard = Loadable(lazy(() => import('./components/guards/RoleGuard')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const Support = Loadable(lazy(() => import('./pages/Support')));
const Settings = Loadable(lazy(() => import('./pages/Settings')));

const routes = [
	{
		path: '/authentication',
		element: (
			<GuestGuard>
				<LogInOverlay />
			</GuestGuard>
		),
		children: [
			{
				path: 'login',
				element: <LoginPage />
			}
		]
	},
	{
		path: '/super-admin',
		element: (
			<AuthGuard>
				<RoleGuard allowedRoles={[ROLES.SUPER_ADMIN]}>
					<MainOverlay />
				</RoleGuard>
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <OverviewSuperAdmin />
			}
		]
	},
	{
		path: '/admin',
		element: (
			<AuthGuard>
				<RoleGuard allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
					<MainOverlay />
				</RoleGuard>
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <OverviewAdmin />
			}
		]
	},
	{
		path: '/worker',
		element: (
			<AuthGuard>
				<RoleGuard allowedRoles={[ROLES.WORKER, ROLES.SUPER_ADMIN]}>
					<MainOverlay />
				</RoleGuard>
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <OverviewWorker />
			},
			{
				path: 'generate',
				element: <Generate />
			}
		]
	},
	{
		path: '/support',
		element: (
			<AuthGuard>
				<MainOverlay />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <Support />
			}
		]
	},
	{
		path: '/settings',
		element: (
			<AuthGuard>
				<MainOverlay />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <Settings />
			}
		]
	},
	{
		path: '/tutorials',
		element: (
			<AuthGuard>
				<MainOverlay />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <Tutorials />
			}
		]
	},
	{
		path: '*',
		element: <Navigate to="/authentication/login" />
	}
];

export default routes;
