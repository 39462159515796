// i18n.ts

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import sk from './lang/sk.json';
import uk from './lang/en.json';
import cz from './lang/cz.json';
import de from './lang/de.json';
import hu from './lang/hu.json';
import ua from './lang/ua.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      uk: { translation: uk },
      sk: { translation: sk },
      cz: { translation: cz },
      de: { translation: de },
      ua: { translation: ua },
      hu: { translation: hu }
    },
    supportedLngs: ['uk', 'sk', 'cz', 'de', 'ua', 'hu'],
    fallbackLng: 'uk'
  });

export default i18n;
