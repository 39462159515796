import { Divider, Popover, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import ReceiptIcon from '@mui/icons-material/Receipt';
import styles from '../../styles/generate.module.css';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

interface InformationPopoverProps {
	text: string[];
	size?: number;
}

const InformationPopover: FC<InformationPopoverProps> = props => {
	const { text, size } = props;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { t } = useTranslation() as any;

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<span
				aria-describedby={open ? 'mouse-over-popover' : undefined}
				aria-haspopup='true'
				onClick={handleClick}
				style={{ cursor: 'pointer' }}
			>
				<HelpIcon style={{ fontSize: `${size}` ? `${size}` : 35, cursor: 'pointer',  marginTop: '5px' }} />
			</span>
			<Popover
				id='mouse-over-popover'
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				<PopoverContainer>
					<div onClick={handleClose}>
						<img
							src={`${process.env.PUBLIC_URL}/cancel.png`}
							width='35px'
							height='35px'
							style={{ cursor: 'pointer', float: 'right' }}
							onClick={handleClose}
						/>
					</div>
					<div className={styles.tutorialHeader}>
						<ReceiptIcon />
						<h3>{t('tutorial')}</h3>
					</div>
					<Divider />
					<div className={styles.informationWrapperText}>
						{text.map((text, index) => (
							<div key={index} className={styles.informationText}>
								<Typography className={styles.subText}>{text}</Typography>
							</div>
						))}
					</div>
				</PopoverContainer>
			</Popover>
		</div>
	);
};

const PopoverContainer = styled('div')(({ theme }) => ({
	padding: theme.spacing(2)
}));

export default InformationPopover;
