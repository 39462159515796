import { IRequirementsDto, IRequirementsStringDto } from '../types/worker/workerDto';

class AppService {
	static getToken = () => window.localStorage.getItem('accessToken');

	static setToken = (token: string) => window.localStorage.setItem('accessToken', token);

	static clearToken = () => window.localStorage.removeItem('accessToken');

	static setSessionData = (data: any, name: string) => window.sessionStorage.setItem(name, JSON.stringify(data));

	static getSessionData<T> (name: string): T {
		return JSON.parse(window.sessionStorage.getItem(name) || '{}');
	}

	// static getSessionDataString = (name: string): IRequirementsStringDto => JSON.parse(window.sessionStorage.getItem(name) || '{}');

	static clearSessionData = (name: string) => window.sessionStorage.removeItem(name);
}

export default AppService;
